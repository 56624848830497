import { useAuth } from '@/shared/hooks/auth-hook';
import { useSrp } from '@/shared/hooks/srp-hook';
import {
  ClassifiedItem,
  Configuration,
  SearchApi,
  SearchDisplayResult,
} from '@/shared/lib-api';
import { useAppSelector } from '@/shared/store/hooks';
import {
  initialSearchPageOptions,
  SetSrpSearchData,
  SetSrpSearchFields,
} from '@/shared/store/reducers/SRPSlice';
import wrapper from '@/shared/store/store';
import { Colors } from '@/shared/util/colors';
import { CarsYourAreaSection } from '@/web/views/components/car-posts-section/cars-your-area-section';
import { LatestSearchesSection } from '@/web/views/components/car-posts-section/latest-searches-section';
import FindCarAfterTypeSection from '@/web/views/components/find-car-after-type-section/find-car-after-type-section';
import { SearchCard } from '@/web/views/components/search-card/search-card';
import { SRPTopBar } from '@/web/views/components/SRP/srp-top-bar';
import { Box, Container, Grid } from '@mui/material';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToolsSection from '../web/views/components/tools-section/tool-section';
import TagManager from 'react-gtm-module';
import { SrpLinksCard } from '@/web/views/components/home/srp-links-card';
import { useApi } from '@/shared/util/api';
import { useDispatch } from 'react-redux';
import { RemoveUndefinedsFromObject } from '@/shared/util/helpers/next-helpers/node-server-helper';
import { PopularModels } from '@/web/views/components/seo-srp/custom-sections/popular-models';
import ContactConsentModal from '@/web/views/components/modals/contactConsentModal';
import { Searchbot } from '@/web/views/components/home/searchbot/searchbot';
import Cookies from 'js-cookie';

const FrontPageContext = React.createContext<
  | {
      submitSearch: () => void;
      askForContactConsent?: (classifiedId: number) => void;
    }
  | undefined
>(undefined);
export const useFrontPageContext = () => {
  const context = useContext(FrontPageContext);
  return context;
};

type IndexPageProps = {
  defaultClassifieds: ClassifiedItem[];
  host: string;
  onlyBrandSearchData: SearchDisplayResult;
};

const IndexPage: NextPage<IndexPageProps> = props => {
  const { t } = useTranslation();
  const shouldLogout = useAppSelector(state => state.auth.shouldLogout);
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const { searchApi } = useApi();
  const router = useRouter();
  const { submitSearch } = useSrp({
    router: router,
    updateUrlOnChange: false,
  });
  const { defaultClassifieds, host } = props;
  const [showContactConsent, setShowContactConsent] = useState({
    show: false,
    classifedId: 0,
  });

  useEffect(() => {
    (async () => {
      let initialResult = await searchApi.apiSearchSearchPost({
        searchPageOptions: { ...initialSearchPageOptions, pageSize: 0 },
      });
      dispatch(SetSrpSearchData(initialResult));
    })();
  }, [dispatch]);

  useEffect(() => {
    if (shouldLogout) {
      logout();
    }
  }, [shouldLogout, logout]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'DL_HOME',
        dlv_pagetype: 'home',
      },
    });
  }, []);

  const displaysearch = Cookies.get('chatbot') == 'test';

  return (
    <>
      <Head>
        <title key="title">{t('HomePage.MetaTitle')}</title>
        <meta
          name="description"
          content={t('HomePage.MetaDescription')}
          key="meta-description"
        />
        <link
          rel="canonical"
          href="https://bilhandel.dk/"
          key="site-canonical"
        />
      </Head>
      <FrontPageContext.Provider
        value={{
          submitSearch: submitSearch,
          askForContactConsent: (classifiedId: number) => {
            setShowContactConsent({ show: true, classifedId: classifiedId });
          },
        }}>
        <Box>
          <SRPTopBar hideSearch={true} bgcolor={Colors.DarkBlue} />
          <SearchCard />
        </Box>
        <Container>
          <ContactConsentModal
            open={showContactConsent.show}
            onClose={() =>
              setShowContactConsent({ show: false, classifedId: 0 })
            }
            classifiedId={showContactConsent.classifedId}
          />
          <Grid container rowSpacing={3} paddingTop={5} paddingBottom={10}>
            <Grid item rowSpacing={3} marginTop={4} xs={12}>
              <FindCarAfterTypeSection />
            </Grid>
            {displaysearch && (
              <Grid item rowSpacing={3} marginTop={4} xs={12}>
                <Searchbot />
              </Grid>
            )}
            <Grid item xs={12} rowSpacing={3} marginTop={4}>
              <LatestSearchesSection />
            </Grid>
            <Grid item rowSpacing={3} marginTop={4}>
              <PopularModels onlyBrand={props.onlyBrandSearchData} />
            </Grid>
            <Grid item rowSpacing={3} marginTop={4}>
              <CarsYourAreaSection defaultClassifieds={defaultClassifieds} />
            </Grid>
            <Grid item rowSpacing={3} marginTop={4}>
              <ToolsSection host={host} />
            </Grid>

            <Grid item rowSpacing={3} marginTop={4} xs={12}>
              <SrpLinksCard />
            </Grid>
          </Grid>
        </Container>
      </FrontPageContext.Provider>
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  store => async context => {
    const api = new SearchApi(
      new Configuration({
        basePath: process.env.INTERNAL_SITE_API,
      }),
    );

    try {
      let { fields, searchResult, popularModels } =
        await api.apiSearchInitfrontfromserverGet();

      store.dispatch(SetSrpSearchFields(fields));
      store.dispatch(SetSrpSearchData(searchResult));

      return {
        props: {
          defaultClassifieds: RemoveUndefinedsFromObject(searchResult.items),
          onlyBrandSearchData: RemoveUndefinedsFromObject(popularModels),
        },
      } as { props: IndexPageProps };
    } catch (err) {
      return { props: {} };
    }
  },
);

export default IndexPage;
