import { useApi } from '@/shared/util/api';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TrackEvent } from '@/shared/util/gtm';
import Cookies from 'js-cookie';
import { useLoginContext } from '@/pages/_app';

type ContactConsentModalProps = {
  open: boolean;
  onClose: () => void;
  classifiedId: number;
};

const cookieDoNotSpamName = 'fav_content_consent_views';

const ContactConsentModal: React.FC<ContactConsentModalProps> = ({
  open,
  onClose,
  classifiedId
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [doNotSpam, setDoNotSpam] = useState<boolean>(true);
  const { likeApi } = useApi();
  const loginContext = useLoginContext();
  

  const handleAccept = async () => {
    setLoading(true);
    TrackEvent('favorite_contact_consent_accepted');

    try {
      await likeApi.apiLikeSetconsentfordealercontactPost({ setConsentForDealerContactRequest: { classifiedId: classifiedId, consent : true } });
      loginContext.openUpdateProfileInformationModal();
      onClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async () => {
    onClose();
    TrackEvent('favorite_contact_consent_declined');
    await likeApi.apiLikeSetconsentfordealercontactPost({ setConsentForDealerContactRequest: { classifiedId: classifiedId, consent : false } });
  };

  useEffect(() => {
    if(open)
    {
      TrackEvent('favorite_contact_consent_shown');
      const value = Cookies.get(cookieDoNotSpamName);
      if(!value)
      {
        Cookies.set(cookieDoNotSpamName, '1', { expires: 10 });
      }
      else
      {
        const newValue = parseInt(value, 10) + 1;
        if(newValue <= 3)
        {
          Cookies.set(cookieDoNotSpamName, newValue.toString(), { expires: 10 });
        }
      }
    }
  }, [open])


  useEffect(() => {
    const cookieValue = Cookies.get(cookieDoNotSpamName);
    if (!cookieValue || parseInt(cookieValue, 10) <= 2) {
      setDoNotSpam(true);
    } else {
      setDoNotSpam(false);
    }
  });

  return (
    <>
      <Dialog open={open && doNotSpam} onClose={handleDecline} id='favoriteContactConsentModal'>
        <DialogTitle  display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box paddingLeft={'5px'} >
            Bilen er gemt som favorit!
          </Box>
        <IconButton onClick={handleDecline}>
            <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{color: '#000'}}>
            Må forhandlere kontakte dig, hvis de har et godt tilbud på bilen?
          </DialogContentText>
          <DialogActions sx={{ marginTop: 2, padding: 0 }}>
            <Button
              id='favoriteContactConsentModalYes'
              onClick={handleAccept}
              color="secondary"
              disabled={loading}              
              sx={{ paddingX: '30px', minWidth: '170px' }}>
              {loading ? 'Gemmer...' : 'Ja tak!'}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContactConsentModal;
