import {
  Box,
  Button,
  Grid,
  Typography,
  Link as MUILink,
  Link,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { PadBox } from '../containers/pad-box';
import { useAppSelector } from '@/shared/store/hooks';
import { SearchPageAttribute } from '@/shared/lib-api/models';
import { BottomLinkSrp } from './bottom-link-srp';
import { initialSearchAttribute } from '@/shared/store/reducers/SRPSlice';
import { BottomSeoContent } from './bottom-seo-content';
import { useSeoTextSrp } from '@/shared/hooks/seo-srp-hook';
import NextLink from 'next/link';
import { Areas } from '@/shared/models/areas';

export const BottomLinksSrp = () => {
  const {
    options,
    makes,
    allFields,
    models,
    carTypes,
    fuelTypes,
    areas,
    cities,
  } = useSeoTextSrp();
  return (
    <>
      <BottomSeoContent />
      {options.makeModelVariantOptions[0].brand.value ===
        initialSearchAttribute.value &&
        makes.length !== 0 && (
          <PadBox bgColor={'#F6FAFB'}>
            <Typography color="primary" fontSize="18px" variant="h5">
              Se alle mærker
            </Typography>
            <BottomLinkSrp text="Makes" fields={makes} urlIndex={1} />
          </PadBox>
        )}

      {options.makeModelVariantOptions[0].brand.value !==
        initialSearchAttribute.value &&
        options.makeModelVariantOptions[0].model.value ===
          initialSearchAttribute.value &&
        models.length !== 0 &&
        options.makeModelVariantOptions.length < 2 && (
          <PadBox bgColor={'#F6FAFB'}>
            <Typography color="primary" fontSize="18px" variant="h5">
              Se alle modeller
            </Typography>
            <BottomLinkSrp text="Models" fields={models} urlIndex={2} />
          </PadBox>
        )}

      {options.carTypes.length === 0 && carTypes.length !== 0 && (
        <PadBox bgColor={'#F6FAFB'}>
          <Typography color="primary" fontSize="18px" variant="h5">
            Se alle biltyper
          </Typography>
          <BottomLinkSrp prefix="biltype-" fields={carTypes} urlIndex={3} />
        </PadBox>
      )}

      {options.fuelTypes.length === 0 && fuelTypes.length !== 0 && (
        <PadBox bgColor={'#F6FAFB'}>
          <Typography color="primary" fontSize="18px" variant="h5">
            Se alle brændstoftyper
          </Typography>
          <BottomLinkSrp
            prefix="braendstoftype-"
            fields={fuelTypes}
            urlIndex={4}
          />
        </PadBox>
      )}

      {options.area?.value == '' && areas.length !== 0 && (
        <PadBox bgColor={'#F6FAFB'}>
          <Typography color="primary" fontSize="18px" variant="h5">
            Se alle områder
          </Typography>
          <BottomLinkSrp prefix="omraade-" fields={areas} urlIndex={5} />
        </PadBox>
      )}

      {(options.city === undefined || options.city?.value === '') &&
        options.area?.value != '' &&
        cities.length !== 0 && (
          <PadBox bgColor={'#F6FAFB'}>
            <Typography color="primary" fontSize="18px" variant="h5">
              Se alle byer i området
            </Typography>
            <BottomLinkSrp prefix="by-" fields={cities} urlIndex={6} />
          </PadBox>
        )}
      {(options.city === undefined || options.city.value === '') &&
        options.area?.value !== '' && (
          <PadBox bgColor={'#F6FAFB'}>
            <NextLink
              href={`/find-forhandler/omraade-${
                Areas.find(p => p.value == options.area.value).value
              }`}>
              <Link variant="clickAble" underline="none">
                <Typography color="primary" fontSize="14px">
                  {`Bilforhandlere ${
                    Areas.find(p => p.value == options.area.value).prepos
                  } ${Areas.find(p => p.value == options.area.value).text}`}
                </Typography>
              </Link>
            </NextLink>
          </PadBox>
        )}
    </>
  );
};
