import { useScreenSizeContext, useSiteSettingContext } from '@/pages/_app';
import { useAppSelector } from '@/shared/store/hooks';
import { useApi } from '@/shared/util/api';
import { Colors } from '@/shared/util/colors';
import PaperPlane from '@/web/views/components/vip/chatbot/paperplane.svg';
import ChatDesktop from '@/web/views/components/vip/chatbot/chat-desktop.svg';
import {
  Box,
  Button,
  IconButton,
  keyframes,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DialogCloseCenter,
  DialogMessageCloseWhite,
} from '../../default-components/dialog/dialog-close';
import { BuildInTrackEvents, TrackEvent } from '@/shared/util/gtm';
import { useVipContext } from '@/pages/[headline]/[[...id]]';

interface Message {
  message: string;
  own: boolean;
  date: number;
}

export const Chatbot = () => {
  const { referer } = useRouter().query;
  const [open, setOpen] = React.useState(referer === 'chat');
  const { item } = useVipContext();
  const { isMobile, isIos, isAndroid } = useScreenSizeContext();

  const [dislayAnimation, setDisplayAnimation] = useState<
    'none' | 'typing' | 'message' | 'messageindicator'
  >('none');
  const initMessage = {
    message: `Hej, kan jeg hjælpe med noget vedr. denne ${item.make} ${item.model}?`,
    own: false,
    date: new Date().getTime(),
  };
  const [hideAnimation, setHideAnimation] = useState(false);
  const [ownMessages, setOwnMessages] = React.useState<Message[]>([]);
  const [botMessages, setBotMessages] = React.useState<Message[]>([
    initMessage,
  ]);
  const [threadId, setThreadId] = React.useState(undefined);

  const { chatbotApi } = useApi();

  const [loading, setLoading] = React.useState(false);
  const [block, setBlock] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const router = useRouter();
  const siteSettings = useSiteSettingContext();
  const prevScrollY = useRef(0);
  const [showBar, setShowBar] = useState(false);
  const chatRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const user = useAppSelector(p => p.auth.cachedUser);
  const [inputInFocus, setInputInFocus] = useState(false);
  const [reminderCount, setReminderCount] = useState(0);
  const [read, setRead] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [initTitle, setInitTitle] = useState(document.title);
  const [botMessageCount, setBotMessageCount] = useState(0);
  //load from localstorage
  useEffect(() => {
    var chattime = localStorage.getItem('chattime');
    var expired = new Date().getTime() - parseInt(chattime) > 3600000; //idle for 1 time

    var expiredAnimation = new Date().getTime() - parseInt(chattime) > 300000; //idle for 5 min
    if (expired) {
      localStorage.removeItem('botMessages1');
      localStorage.removeItem('ownMessages1');
      localStorage.removeItem('threadId');
      localStorage.removeItem('chattime');
      localStorage.removeItem('reminderCount');
      localStorage.removeItem('read');
      localStorage.removeItem('unreadCount');
    }
    if (expiredAnimation) {
      localStorage.removeItem('hideAnimation');
    }
    if (expired || expiredAnimation) {
      return;
    }

    var reminderCount = localStorage.getItem('reminderCount');
    if (reminderCount) {
      setReminderCount(parseInt(reminderCount));
    }
    var unreadCount = localStorage.getItem('unreadCount');
    if (unreadCount) {
      setUnreadCount(parseInt(unreadCount));
    }

    var read = localStorage.getItem('read');
    if (read === 'true') {
      setRead(true);
    } else if (read === 'false') {
      setRead(false);
    }

    var threadId = localStorage.getItem('threadId');
    if (threadId) {
      setThreadId(threadId);
    }
    var botMessages = localStorage.getItem('botMessages1');
    if (botMessages) {
      setBotMessages(JSON.parse(botMessages));
    }
    var ownMessages = localStorage.getItem('ownMessages1');
    if (ownMessages) {
      setOwnMessages(JSON.parse(ownMessages));
    }
    var hideAnimation = localStorage.getItem('hideAnimation');
    if (hideAnimation) {
      setHideAnimation(true);
    }
  }, []);

  const hideAnimationFunc = useCallback(() => {
    setHideAnimation(true);
    localStorage.setItem('hideAnimation', 'true');
  }, []);

  useEffect(() => {
    if (!open && dislayAnimation != 'none') {
      hideAnimationFunc();
    }
  }, [open]);

  useEffect(() => {
    if (!open && botMessages.length > 1 && !read) {
      setDisplayAnimation('messageindicator');
    } else {
      setDisplayAnimation('none');
    }
  }, [botMessages, read, open]);

  useEffect(() => {
    if (open) {
      setRead(true);
      setUnreadCount(0);
      localStorage.setItem('unreadCount', '0');
      document.title = initTitle;
      localStorage.setItem('read', 'true');
      setTimeout(() => {
        if (chatRef.current) {
          chatRef.current.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: 'smooth',
          });
          if (!isMobile) {
            inputRef.current.focus();
          }
        }
      }, 200);
    }
  }, [open]);

  useEffect(() => {
    if (!open && botMessageCount != 0) {
      setRead(false);
      setUnreadCount(p => p + 1);
      localStorage.setItem('unreadCount', `${unreadCount + 1}`);
      document.title = `(${unreadCount + 1} ${
        unreadCount + 1 > 1 ? 'nye beskeder' : 'ny besked'
      }) ${initTitle}`;
      localStorage.setItem('read', 'false');
    }

    if (botMessageCount !== 0) {
      var audio = new Audio('/alert.mp3');
      audio.volume = 0.4;
      audio.play();
    }
  }, [botMessageCount]);

  const [keyboardHeight, setKeyboardHeight] = useState(0);

  //on open mobile
  useEffect(() => {
    if (!isMobile) {
      return;
    }
    const func = event => {
      if (isIos) {
        scrollTo(0, 0);
        setTimeout(() => {
          const visualHeight = (event.target as any).height;
          const windowHeight = window.document.body.clientHeight;
          setKeyboardHeight(windowHeight - visualHeight);
        }, 100);
      } else {
        scrollTo(0, 0);
        const visualHeight = (event.target as any).height;
        const windowHeight = window.document.body.clientHeight;
        setKeyboardHeight(windowHeight - visualHeight);
      }
    };
    if (open) {
      window.scrollTo(0, 0);
      visualViewport.addEventListener('resize', func);
      window.document.body.style.position = 'fixed';
      window.document.body.style.left = '0';
      window.document.body.style.right = '0';
      window.document.body.style.top = '0';
      window.document.body.style.bottom = '0';
    } else {
      visualViewport.removeEventListener('resize', func);
      window.document.body.style.position = 'unset';
      window.document.body.style.left = 'unset';
      window.document.body.style.right = 'unset';
      window.document.body.style.top = 'unset';
      window.document.body.style.bottom = 'unset';
    }
    return () => {
      visualViewport.removeEventListener('resize', func);
      window.document.body.style.position = 'unset';
      window.document.body.style.left = 'unset';
      window.document.body.style.right = 'unset';
      window.document.body.style.top = 'unset';
      window.document.body.style.bottom = 'unset';
    };
  }, [open, isMobile, isIos]);

  const resetChat = useCallback(() => {
    localStorage.removeItem('botMessages1');
    localStorage.removeItem('ownMessages1');
    localStorage.removeItem('threadId');
    localStorage.removeItem('chattime');
    localStorage.removeItem('reminderCount');
    localStorage.removeItem('unreadCount');
    setOwnMessages([]);
    setBotMessages([initMessage]);
    setThreadId(undefined);
    setBlock(false);
    setMessage('');
    setLoading(false);
    setReminderCount(0);
    setUnreadCount(0);
  }, [item.make, item.model]);

  const [loadingTimer, setLoadingTimer] = React.useState(0);
  //animations
  useEffect(() => {
    var id = setInterval(() => {
      setLoadingTimer(t => (t === 3 ? 0 : t + 1));
    }, 500);
    var id3 = undefined;
    var id2 = setTimeout(() => {
      //typing
      setDisplayAnimation('typing');
      id3 = setTimeout(() => {
        //message
        setDisplayAnimation('message');
      }, 3000);
    }, 4000);

    return () => {
      clearInterval(id);
      clearTimeout(id2);
      if (id3) {
        clearTimeout(id3);
      }
    };
  }, []);

  const allMessages = useMemo(() => {
    var result: Message[] = [];
    for (var i = 0; i < botMessages.length; i++) {
      var botRes = botMessages[i];
      var botResMessage = botRes.message;
      //replace headline start
      var regex = /###(.*)/g;
      var match = regex.exec(botResMessage);
      while (match != null && match.length > 1) {
        var text = match[0].replace(match[2], '').replace('###', '');
        botResMessage = botResMessage.replace(
          match[0],
          `<div style="font-size: 15px;"><b>${text}</b></div>`,
        );
        match = regex.exec(botResMessage);
      }
      //replace headline end
      //replace bold start
      var regex = /\*\*(.*)\*\*/g;
      var match = regex.exec(botResMessage);
      while (match != null && match.length > 1) {
        var text = match[0]
          .replace(match[2], '')
          .replace('**', '')
          .replace('**', '');
        botResMessage = botResMessage.replace(match[0], `<b>${text}</b>`);
        match = regex.exec(botResMessage);
      }
      //replace bold end
      //replace links start
      var regex = /(\[.*\]\((.*)\))/g;
      var match = regex.exec(botResMessage);
      while (match != null && match.length > 1) {
        var text = match[0]
          .replace(match[2], '')
          .replace('[', '')
          .replace(']', '')
          .replace('(', '')
          .replace(')', '');
        botResMessage = botResMessage.replace(
          match[0],
          `<a style="text-decoration: underline" target="new" href="${match[2]}?referer=chat">${text}</a>`,
        );
        match = regex.exec(botResMessage);
      }
      //replace links end
      result.push({ ...botRes, message: botResMessage });
    }
    result = result.concat(ownMessages);
    result = result.sort((a, b) => a.date - b.date);
    return result;
  }, [botMessages, ownMessages]);
  //scroll
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const showNow = currentScrollY > 600;
      if (showNow !== showBar) {
        setShowBar(showNow);
      }
      prevScrollY.current = currentScrollY;
    };
    document.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  const sendMessage = useCallback(
    async (overrideMessage?: string) => {
      if ((overrideMessage || (message && message != '')) && !block) {
        setOwnMessages([
          ...ownMessages,
          {
            message: overrideMessage ?? message,
            own: true,
            date: new Date().getTime(),
          },
        ]);
        localStorage.setItem('chattime', new Date().getTime().toString());
        localStorage.setItem(
          'ownMessages1',
          JSON.stringify([
            ...ownMessages,
            {
              message: overrideMessage ?? message,
              own: true,
              date: new Date().getTime(),
            },
          ]),
        );
        setTimeout(() => {
          chatRef?.current?.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }, 200);
        setMessage('');
        var id = setTimeout(() => {
          setLoading(true);
          setTimeout(() => {
            chatRef?.current?.scrollTo({
              top: chatRef.current.scrollHeight,
              behavior: 'smooth',
            });
          }, 200);
        }, 2500);
        setBlock(true);
        setReminderCount(0);
        const response = await chatbotApi.apiChatbotSendMessagePost({
          messageRequest: {
            message: overrideMessage ?? message,
            threadId: threadId,
            name: user?.personalInfo.screenName ?? undefined,
            email: user?.personalInfo.email ?? undefined,
            phone: user?.personalInfo.phoneNumber ?? undefined,
            classifiedItemJson: JSON.stringify(item),
            vipUrl:
              (siteSettings.name == 'Localhost'
                ? 'https://bilhandel.dk'
                : siteSettings.hostname) + router.asPath,
          },
        });
        if (
          response.message ==
          'Super! Beskeden er nu sendt til forhandleren, og jeg har samtidig sendt en kopi til din mail. Er der andet jeg kan hjælpe med?'
        ) {
          TrackEvent(BuildInTrackEvents.OnDealerMessageSentChatbot);
        }
        setThreadId(response.threadId);
        localStorage.setItem('threadId', response.threadId);
        clearTimeout(id);
        setLoading(false);

        setBlock(false);

        if (response.message) {
          setBotMessages([
            ...botMessages,
            {
              message: response.message,
              own: false,
              date: new Date().getTime(),
            },
          ]);
          setBotMessageCount(botMessages.length + 1);
          localStorage.setItem(
            'botMessages1',
            JSON.stringify([
              ...botMessages,
              {
                message: response.message,
                own: false,
                date: new Date().getTime(),
              },
            ]),
          );
        }

        setTimeout(() => {
          chatRef?.current?.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: 'smooth',
          });
          if (!isMobile) {
            inputRef?.current?.focus();
          }
        }, 200);
      }
    },
    [
      botMessages,
      chatbotApi,
      message,
      ownMessages,
      router.asPath,
      threadId,
      block,
      user?.personalInfo.email,
      user?.personalInfo.phoneNumber,
      user?.personalInfo.screenName,
      siteSettings,
    ],
  );

  const pulse = keyframes`
  0% {
    transform: scale(1);
  }
    50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;
  const displayTyping = !open && !hideAnimation && dislayAnimation == 'typing';
  const displayMessage =
    !open && !hideAnimation && dislayAnimation == 'message';

  const displayMessageIndicator =
    !open && dislayAnimation == 'messageindicator';

  const mobileLandscape =
    isMobile &&
    (screen.orientation.angle === 90 || screen.orientation.angle === 270);

  const awaitingOK =
    botMessages[botMessages.length - 1]?.message?.includes('"ok"') &&
    !allMessages[allMessages.length - 1].own;

  //animations
  useEffect(() => {
    if (!awaitingOK) {
      return;
    }
    var id = setTimeout(() => {
      if (reminderCount > 0) {
        return;
      }
      var message = 'Skriv "ok" eller tryk på knappen for at sende beskeden';
      setBotMessages([
        ...botMessages,
        { message: message, own: false, date: new Date().getTime() },
      ]);
      setBotMessageCount(botMessages.length + 1);
      chatbotApi.apiChatbotUpdateremindercountPost({
        threadId: threadId,
        reminderCount: 1,
      });
      setReminderCount(1);
      localStorage.setItem('reminderCount', '1');
      localStorage.setItem(
        'botMessages1',
        JSON.stringify([
          ...botMessages,
          { message: message, own: false, date: new Date().getTime() },
        ]),
      );

      setTimeout(() => {
        if (chatRef.current) {
          chatRef.current.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: 'smooth',
          });
          if (!isMobile) {
            inputRef.current.focus();
          }
        }
      }, 200);
    }, 9000);
    var id2 = setTimeout(() => {
      if (reminderCount == 2) {
        return;
      }
      var message =
        'Hvis du vil sende beskeden til forhandleren skal du skrive "ok" eller trykke på knappen.';
      setBotMessages([
        ...botMessages,
        { message: message, own: false, date: new Date().getTime() },
      ]);
      setBotMessageCount(botMessages.length + 1);
      chatbotApi.apiChatbotUpdateremindercountPost({
        threadId: threadId,
        reminderCount: 2,
      });
      setReminderCount(2);
      setOpen(true); //open on second reminder
      localStorage.setItem('reminderCount', '2');
      localStorage.setItem(
        'botMessages1',
        JSON.stringify([
          ...botMessages,
          { message: message, own: false, date: new Date().getTime() },
        ]),
      );

      setTimeout(() => {
        if (chatRef.current) {
          chatRef.current.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: 'smooth',
          });
          if (!isMobile) {
            inputRef.current.focus();
          }
        }
      }, 200);
    }, 12000);
    return () => {
      clearInterval(id);

      clearTimeout(id2);
    };
  }, [awaitingOK, botMessages, isMobile, reminderCount]);
  return (
    <Box
      zIndex={1000}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-end'}
      position={'fixed'}
      alignItems={'end'}
      bottom={
        showBar
          ? 96
          : isMobile && !mobileLandscape && inputInFocus
          ? `${keyboardHeight + 20}px`
          : 20
      }
      left={isMobile ? 10 : 'unset'}
      right={isMobile ? 10 : 20}>
      {open && (
        <>
          <Box
            marginBottom={isMobile && mobileLandscape && inputInFocus ? 0 : 1}
            boxShadow={'0px 0px 24px 0px #00000040'}
            bgcolor={Colors.White}
            borderRadius={'22px'}
            display={'flex'}
            flexDirection={'column'}
            height={
              isMobile
                ? mobileLandscape
                  ? inputInFocus
                    ? `calc(100vh - ${isIos ? 114 : 84}px)` //bottom (20) + topgap (8) + topbar? (ios: 86, android: 56)
                    : `calc(100vh - ${isIos ? 170 : 140}px)` //bottom (20) + icon (48) + marginBottom (8) + topgap (8) + topbar? (ios: 86, android: 56) keyobard doesnt affect viewpot heigth
                  : inputInFocus
                  ? `calc(100vh - ${(isIos ? 170 : 140) + keyboardHeight}px)` //bottom (20) + icon (48) + marginBottom (8) + topgap (8) + topbar? (ios: 86, android: 56) + keyboard height
                  : `calc(100vh - ${isIos ? 170 : 140}px)` //bottom (20) + icon (48) + marginBottom (8) + topgap (8) + topbar? (ios: 86, android: 56)
                : 'unset'
            }
            maxHeight={isMobile ? 'unset' : 'calc(90vh - 72px)'}
            width={isMobile ? '100%' : 400}
            maxWidth={isMobile ? 'unset' : 400}>
            <Box
              zIndex={1}
              borderRadius={'19px 19px 0px 0px'}
              boxShadow={'0px 0px 24px 0px #00000040'}
              bgcolor={Colors.Secondary}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              padding={2}>
              <Typography color={Colors.White} fontSize={16} fontWeight={900}>
                Bilhandel.dk
              </Typography>

              <Box display={'flex'} alignItems={'center'}>
                {ownMessages.length > 0 && (
                  <Typography
                    color={Colors.White}
                    marginRight={4}
                    fontSize={16}
                    variant="clickAble"
                    lineHeight={'22px'}
                    onClick={resetChat}>
                    Ryd samtale
                  </Typography>
                )}
                <DialogCloseCenter onClose={() => setOpen(false)} />
              </Box>
            </Box>
            <Box
              ref={chatRef}
              maxHeight={isMobile ? 'unset' : '690px'}
              height={isMobile ? 500 : '80vh'}
              overflow={'auto'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'baseline'}
              flexGrow={1}
              borderBottom={'1px solid ' + Colors.Muted}
              paddingTop={2}
              paddingBottom={1}
              paddingX={1}>
              {allMessages.map((m, i) => (
                <React.Fragment key={m.message}>
                  <Box
                    flexDirection={m.own ? 'row-reverse' : 'row'}
                    alignSelf={m.own ? 'flex-end' : 'flex-start'}
                    display="flex"
                    alignItems={'flex-end'}
                    marginBottom={1}>
                    <Box
                      height={10}
                      width={10}
                      bgcolor={
                        m.own ? Colors.BlurredTextColor : Colors.Secondary
                      }>
                      <Box
                        height={10}
                        width={10}
                        borderRadius={
                          m.own ? '0px 0px 0px 70%' : '0px 0px 70% 0px'
                        }
                        bgcolor={Colors.White}
                      />
                    </Box>
                    <Box
                      padding={1}
                      borderRadius={
                        m.own ? '12px 12px 0px 12px' : '12px 12px 12px 0px'
                      }
                      bgcolor={
                        m.own ? Colors.BlurredTextColor : Colors.Secondary
                      }>
                      <Typography
                        whiteSpace={'break-spaces'}
                        color={Colors.White}>
                        <span dangerouslySetInnerHTML={{ __html: m.message }} />
                      </Typography>
                    </Box>
                  </Box>
                  {i === 0 && (
                    <Box width={'100%'} marginBottom={1}>
                      <Typography
                        textAlign={'center'}
                        fontSize={'11px'}
                        lineHeight={'14.4px'}
                        color="#3F3F3F">
                        Ved brug af chatten accepterer du vores{' '}
                        <a href="https://pace.dk/betingelser/" target="_blank">
                          vilkår
                        </a>{' '}
                        og{' '}
                        <a
                          href="https://pace.dk/gdpr-og-privatlivspolitik/"
                          target="_blank">
                          privatlivspolitik
                        </a>
                      </Typography>
                    </Box>
                  )}
                </React.Fragment>
              ))}
              {loading && (
                <Box display="flex" alignItems={'flex-end'} marginBottom={1}>
                  <Box height={10} width={10} bgcolor={Colors.Secondary}>
                    <Box
                      height={10}
                      width={10}
                      borderRadius={'0px 0px 70% 0px'}
                      bgcolor={Colors.White}
                    />
                  </Box>
                  <Box
                    padding={1}
                    borderRadius={'12px 12px 12px 0px'}
                    bgcolor={Colors.Secondary}>
                    <Typography color={Colors.White}>
                      {[1, 2, 3].map(p => (p === loadingTimer ? ' · ' : ' . '))}
                    </Typography>
                  </Box>
                </Box>
              )}
              {awaitingOK && (
                <>
                  <Box paddingX={1} display="flex" marginBottom={1}>
                    <Typography
                      fontSize={'11px'}
                      lineHeight={'14.4px'}
                      color="#3F3F3F">
                      Hvis du går videre, samtykker du til, at vi sender dine
                      personoplysninger til forhandleren samt at modtage emails
                      vedr. bilen, fx ved ændringer af annoncen,
                      finansieringstilbud eller lignende. Læs om behandlingen af
                      dine personoplysninger i vores{' '}
                      <a
                        style={{ color: Colors.Secondary }}
                        href="https://pace.dk/gdpr-og-privatlivspolitik/"
                        target="_blank">
                        persondatapolitik
                      </a>
                    </Typography>
                  </Box>
                  <Box display="flex" alignSelf={'flex-end'} marginBottom={1}>
                    <Button
                      style={{
                        backgroundColor: 'transparent',
                        borderRadius: '30px',
                        border: '2px solid #0f60ac',
                        color: '#0f60ac',
                      }}
                      onClick={() => sendMessage('ok')}>
                      Det er fint - send beskeden
                    </Button>
                  </Box>
                </>
              )}
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              paddingY={1}
              paddingLeft={'18px'}
              paddingRight={1}>
              <Box display={'flex'} alignItems={'center'} flexGrow={1}>
                <TextareaAutosize
                  maxRows={3}
                  onFocus={() => setInputInFocus(true)}
                  onBlur={() => setInputInFocus(false)}
                  ref={inputRef}
                  style={{
                    fontFamily: 'inherit',
                    border: 'none',
                    resize: 'none',
                    width: '100%',
                    outline: 'none',
                    fontSize: 16,
                  }}
                  value={message}
                  onKeyDown={e => {
                    e.stopPropagation();
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                  onChange={e => setMessage(e.target.value)}
                  placeholder="Skriv din besked her.."
                />
              </Box>
              <Box
                component={IconButton}
                onTouchEnd={() => sendMessage()}
                onClick={() => sendMessage()}
                borderRadius={'50%'}
                bgcolor={Colors.Secondary}
                style={{ cursor: 'pointer' }}
                alignItems={'center'}
                justifyContent={'center'}
                display={'flex'}
                height={40}
                width={40}>
                <img
                  alt="Send besked"
                  src={PaperPlane.src}
                  width={24}
                  height={24}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Box
        style={{
          filter: 'drop-shadow(0px 0px 24px #00000040)',
        }}
        display={isMobile && inputInFocus && mobileLandscape ? 'none' : 'flex'}
        flexDirection={'column'}
        alignItems={open || displayMessage ? 'end' : 'center'}>
        {displayTyping && (
          <Box marginBottom={1}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}>
              <Box
                paddingY={1}
                paddingX={1.5}
                borderRadius={'12px'}
                bgcolor={Colors.Secondary}>
                <Typography fontWeight={900} color={Colors.White}>
                  {[1, 2, 3].map(p => (p === loadingTimer ? ' · ' : ' . '))}
                </Typography>
              </Box>
              <Box
                style={{ transform: 'rotate(45deg)', marginTop: -8 }}
                padding={1}
                bgcolor={Colors.Secondary}></Box>
            </Box>
          </Box>
        )}
        {displayMessage && (
          <Box
            marginBottom={1}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setOpen(open => !open)}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'end'}>
              <Box
                paddingY={1}
                display={'flex'}
                paddingRight={1.5}
                paddingLeft={1.5}
                borderRadius={'12px'}
                bgcolor={Colors.Secondary}>
                <Typography fontSize={isMobile ? 13 : 14} color={Colors.White}>
                  Hej, har du spørgsmål til bilen?
                </Typography>
                <DialogMessageCloseWhite
                  onClose={e => {
                    e.stopPropagation();
                    hideAnimationFunc();
                  }}
                />
              </Box>
              <Box
                style={{
                  transform: 'rotate(45deg)',
                  marginTop: -8,
                  marginRight: isMobile ? 18 : 54,
                }}
                padding={1}
                bgcolor={Colors.Secondary}></Box>
            </Box>
          </Box>
        )}
        <Box
          display={'flex'}
          alignItems={'center'}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setOpen(open => !open)}>
          <Box
            bgcolor={Colors.Secondary}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            height={isMobile ? 48 : 64}
            width={isMobile ? 48 : 64}
            position={'relative'}
            borderRadius={'50%'}>
            <Box
              sx={{
                animation: `${pulse} 2000ms ease`,
                animationIterationCount: 'infinite',
              }}
              position={'absolute'}
              top={displayMessage || displayMessageIndicator ? -5 : 0}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              left={displayMessage || displayMessageIndicator ? -5 : 0}
              height={displayMessage || displayMessageIndicator ? 25 : 15}
              width={displayMessage || displayMessageIndicator ? 25 : 15}
              borderRadius={'50%'}
              bgcolor={
                displayMessage || displayMessageIndicator
                  ? '#FC0D0D'
                  : '#83f535'
              }>
              {(displayMessage || displayMessageIndicator) && (
                <Typography fontSize={16} fontWeight={700} color={Colors.White}>
                  {displayMessage ? 1 : unreadCount}
                </Typography>
              )}
            </Box>
            <img
              src={ChatDesktop.src}
              height={isMobile ? 25 : 30}
              width={isMobile ? 25 : 30}
              alt="chat"
            />
          </Box>
          {!isMobile && (
            <Box
              bgcolor={Colors.Secondary}
              paddingY={2}
              paddingLeft={2}
              paddingRight={4}
              marginLeft={-2}
              borderRadius={'0px 30px 30px 0px'}>
              <Typography fontSize={16} fontWeight={900} color={Colors.White}>
                {open ? 'Luk chat' : 'Vi er online'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
