import React from 'react';
import { useAppSelector } from '@/shared/store/hooks';
import { SearchBarSRP } from './search-bar-srp';
import { Skeleton, Typography } from '@mui/material';
import {
  GetH1WithArea,
  GetH1Amount,
} from '@/web/util/helpers/seo-srp-helpers/title-meta-helpers';
import { LoadingState } from '@/shared/util/general-helpers';
import { useScreenSizeContext } from '@/pages/_app';
export const DidFindResults = (props: { loadingState: LoadingState }) => {
  const searchOptions = useAppSelector(state => state.srpReducer.searchOptions);
  const searchResult = useAppSelector(state => state.srpReducer.searchData);
  const { isMobile } = useScreenSizeContext();

  return (
    <SearchBarSRP header={''}>
      <Typography display="inline" fontSize={18}>
        {isMobile ? '' : 'Vi har fundet '}
      </Typography>
      <Typography component={'h1'} display={'inline'} fontSize={18}>
        {props.loadingState === LoadingState.Loading ? (
          <Skeleton
            sx={{ display: 'inline-flex', marginRight: '4px' }}
            width={55}
          />
        ) : (
          <span
            dangerouslySetInnerHTML={{
              __html: GetH1Amount(searchResult),
            }}></span>
        )}
        <span
          dangerouslySetInnerHTML={{
            __html: GetH1WithArea(searchResult, searchOptions),
          }}></span>
      </Typography>
    </SearchBarSRP>
  );
};
