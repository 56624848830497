import { useScreenSizeContext, useSiteSettingContext } from '@/pages/_app';
import { ClassifiedItem } from '@/shared/lib-api';
import { useApi } from '@/shared/util/api';
import { Colors } from '@/shared/util/colors';
import PaperPlane from '@/web/views/components/vip/chatbot/paperplane.svg';
import {
  Box,
  Grid,
  IconButton,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import BasicCarListCard from '../../cards/basic-car-card/basic-car-list-card';
import { BasicCarCardType } from '@/shared/hooks/basic-car-hook';
import { BasicCarGalleryCard } from '../../cards/basic-car-card/basic-car-gallery-card';

interface Message {
  message: string;
  items?: ClassifiedItem[];
  own: boolean;
  date: number;
}

const initialQuestions = [
  'Jeg skal bruge en pendlerbil',
  'Jeg ønsker en bil med plads til barnevogn',
  'Jeg leder efter en bil til min teenager',
  'Jeg tænker på klimaet, så jeg skal bruge en økonomisk elbil',
];

export const Searchbot = () => {
  const { isMobile } = useScreenSizeContext();
  const initMessage = {
    message: `Fortæl hvad du leder efter og vores AI finder den rigtige bil til dig`,
    own: false,
    date: new Date().getTime(),
  };

  const [ownMessages, setOwnMessages] = React.useState<Message[]>([]);
  const [botMessages, setBotMessages] = React.useState<Message[]>([
    initMessage,
  ]);
  const [threadId, setThreadId] = React.useState(undefined);

  const { searchbotApi } = useApi();

  const [loading, setLoading] = React.useState(false);
  const [block, setBlock] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const router = useRouter();
  const siteSettings = useSiteSettingContext();
  const chatRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [inputInFocus, setInputInFocus] = useState(false);
  const [botMessageCount, setBotMessageCount] = useState(0);

  //load from localstorage
  useEffect(() => {
    var chattime = localStorage.getItem('searchchattime');
    var expired = new Date().getTime() - parseInt(chattime) > 3600000; //idle for 1 time

    if (expired) {
      localStorage.removeItem('searchbotMessages1');
      localStorage.removeItem('searchownMessages1');
      localStorage.removeItem('searchthreadId');
      localStorage.removeItem('searchchattime');
      localStorage.removeItem('searchreminderCount');
    }
    if (expired) {
      return;
    }

    var threadId = localStorage.getItem('searchthreadId');
    if (threadId) {
      setThreadId(threadId);
    }
    var botMessages = localStorage.getItem('searchbotMessages1');
    if (botMessages) {
      setBotMessages(JSON.parse(botMessages));
    }
    var ownMessages = localStorage.getItem('searchownMessages1');
    if (ownMessages) {
      setOwnMessages(JSON.parse(ownMessages));
    }
  }, []);

  useEffect(() => {
    if (botMessageCount !== 0) {
      var audio = new Audio('/alert.mp3');
      audio.volume = 0.4;
      audio.play();
    }
  }, [botMessageCount]);

  const resetChat = useCallback(() => {
    localStorage.removeItem('searchbotMessages1');
    localStorage.removeItem('searchownMessages1');
    localStorage.removeItem('searchthreadId');
    localStorage.removeItem('searchchattime');
    localStorage.removeItem('searchreminderCount');
    localStorage.removeItem('searchunreadCount');
    setOwnMessages([]);
    setBotMessages([initMessage]);
    setThreadId(undefined);
    setBlock(false);
    setMessage('');
    setLoading(false);
  }, []);

  const allMessages = useMemo(() => {
    var result: Message[] = [];
    for (var i = 0; i < botMessages.length; i++) {
      var botRes = botMessages[i];
      var botResMessage = botRes.message;
      //replace headline start
      var regex = /###(.*)/g;
      var match = regex.exec(botResMessage);
      while (match != null && match.length > 1) {
        var text = match[0].replace(match[2], '').replace('###', '');
        botResMessage = botResMessage.replace(
          match[0],
          `<div style="font-size: 15px;"><b>${text}</b></div>`,
        );
        match = regex.exec(botResMessage);
      }
      //replace headline end
      //replace bold start
      var regex = /\*\*(.*)\*\*/g;
      var match = regex.exec(botResMessage);
      while (match != null && match.length > 1) {
        var text = match[0]
          .replace(match[2], '')
          .replace('**', '')
          .replace('**', '');
        botResMessage = botResMessage.replace(match[0], `<b>${text}</b>`);
        match = regex.exec(botResMessage);
      }
      //replace bold end
      //replace links start
      var regex = /(\[.*\]\((.*)\))/g;
      var match = regex.exec(botResMessage);
      while (match != null && match.length > 1) {
        var text = match[0]
          .replace(match[2], '')
          .replace('[', '')
          .replace(']', '')
          .replace('(', '')
          .replace(')', '');
        botResMessage = botResMessage.replace(
          match[0],
          `<a style="text-decoration: underline" target="new" href="${match[2]}?referer=chat">${text}</a>`,
        );
        match = regex.exec(botResMessage);
      }
      //replace links end
      result.push({ ...botRes, message: botResMessage });
    }
    result = result.concat(ownMessages);
    result = result.sort((a, b) => a.date - b.date);
    return result;
  }, [botMessages, ownMessages]);

  const sendMessage = useCallback(
    async (overrideMessage?: string) => {
      if ((overrideMessage || (message && message != '')) && !block) {
        setOwnMessages([
          ...ownMessages,
          {
            message: overrideMessage ?? message,
            own: true,
            date: new Date().getTime(),
          },
        ]);
        localStorage.setItem('searchchattime', new Date().getTime().toString());
        localStorage.setItem(
          'searchownMessages1',
          JSON.stringify([
            ...ownMessages,
            {
              message: overrideMessage ?? message,
              own: true,
              date: new Date().getTime(),
            },
          ]),
        );
        setTimeout(() => {
          chatRef?.current?.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }, 200);
        setMessage('');
        var id = setTimeout(() => {
          setLoading(true);
          setTimeout(() => {
            chatRef?.current?.scrollTo({
              top: chatRef.current.scrollHeight,
              behavior: 'smooth',
            });
          }, 200);
        }, 2500);
        setBlock(true);
        const response = await searchbotApi.apiSearchbotSendMessagePost({
          searchbotMessageRequest: {
            message: overrideMessage ?? message,
            threadId: threadId,
            url:
              (siteSettings.name == 'Localhost'
                ? 'https://bilhandel.dk'
                : siteSettings.hostname) + router.asPath,
          },
        });
        // if (
        //   response.message ==
        //   'Super! Beskeden er nu sendt til forhandleren, og jeg har samtidig sendt en kopi til din mail. Er der andet jeg kan hjælpe med?'
        // ) {
        //   TrackEvent(BuildInTrackEvents.OnDealerMessageSentChatbot);
        // }
        setThreadId(response.threadId);
        localStorage.setItem('searchthreadId', response.threadId);
        clearTimeout(id);
        setLoading(false);

        setBlock(false);

        if (response.message) {
          setBotMessages([
            ...botMessages,
            {
              message: response.message,
              items: response.classifiedItems,
              own: false,
              date: new Date().getTime(),
            },
          ]);
          setBotMessageCount(botMessages.length + 1);
          localStorage.setItem(
            'searchbotMessages1',
            JSON.stringify([
              ...botMessages,
              {
                message: response.message,
                items: response.classifiedItems,
                own: false,
                date: new Date().getTime(),
              },
            ]),
          );
        }

        setTimeout(() => {
          chatRef?.current?.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: 'smooth',
          });
          if (!isMobile) {
            inputRef?.current?.focus();
          }
        }, 200);
      }
    },
    [
      botMessages,
      searchbotApi,
      message,
      ownMessages,
      router.asPath,
      threadId,
      block,
      siteSettings,
    ],
  );

  const [loadingTimer, setLoadingTimer] = React.useState(0);
  //animations
  useEffect(() => {
    var id = setInterval(() => {
      setLoadingTimer(t => (t === 3 ? 0 : t + 1));
    }, 500);

    return () => {
      clearInterval(id);
    };
  }, []);

  const mobileLandscape =
    isMobile &&
    (screen.orientation.angle === 90 || screen.orientation.angle === 270);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography marginBottom={2} variant={'h2'}>
        Find bilen der får dit hjerte til at banke
      </Typography>
      <Box
        marginBottom={isMobile && mobileLandscape && inputInFocus ? 0 : 1}
        width={'100%'}
        bgcolor={Colors.White}
        borderRadius={'22px'}
        display={'flex'}
        flexDirection={'column'}>
        <Box
          zIndex={1}
          borderRadius={'19px 19px 0px 0px'}
          boxShadow={'0px 0px 24px 0px #00000040'}
          bgcolor={Colors.Secondary}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          padding={2}>
          <Typography color={Colors.White} fontSize={16} fontWeight={900}>
            Bilhandel.dk
          </Typography>

          <Box display={'flex'} alignItems={'center'}>
            {ownMessages.length > 0 && (
              <Typography
                color={Colors.White}
                fontSize={16}
                variant="clickAble"
                lineHeight={'22px'}
                onClick={resetChat}>
                Ryd samtale
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          ref={chatRef}
          minHeight={isMobile ? 'unset' : '490px'}
          overflow={'auto'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'baseline'}
          flexGrow={1}
          borderBottom={'1px solid ' + Colors.Muted}
          paddingTop={2}
          paddingBottom={1}
          paddingX={1}>
          {allMessages.map((m, i) => (
            <React.Fragment key={m.message}>
              <Box
                flexDirection={m.own ? 'row-reverse' : 'row'}
                alignSelf={m.own ? 'flex-end' : 'flex-start'}
                display="flex"
                alignItems={'flex-end'}
                marginBottom={1}>
                <Box
                  height={10}
                  width={10}
                  bgcolor={m.own ? Colors.BlurredTextColor : Colors.Secondary}>
                  <Box
                    height={10}
                    width={10}
                    borderRadius={m.own ? '0px 0px 0px 70%' : '0px 0px 70% 0px'}
                    bgcolor={Colors.White}
                  />
                </Box>
                <Box
                  padding={1}
                  borderRadius={
                    m.own ? '12px 12px 0px 12px' : '12px 12px 12px 0px'
                  }
                  bgcolor={m.own ? Colors.BlurredTextColor : Colors.Secondary}>
                  <Typography whiteSpace={'break-spaces'} color={Colors.White}>
                    <span dangerouslySetInnerHTML={{ __html: m.message }} />
                  </Typography>
                </Box>
              </Box>
              {i === 0 && ownMessages.length == 0 && (
                <Box width={'100%'} marginBottom={1}>
                  <Typography textAlign={'center'} color="#3F3F3F">
                    Du kan f.eks. spørge om
                  </Typography>
                  <Grid marginTop={2} columnSpacing={2} container>
                    {initialQuestions.map((q, i) => (
                      <Grid key={q} item xs={6} sm={3}>
                        <Box
                          onClick={() => {
                            sendMessage(q);
                          }}
                          bgcolor={Colors.BlurredTextColor}
                          borderRadius={2}
                          minHeight={100}
                          style={{ cursor: 'pointer' }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          padding={3}>
                          <Typography
                            fontSize={18}
                            textAlign={'center'}
                            color={Colors.White}>
                            {q}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
              {m.items && m.items.length != 0 && (
                <Box padding={1} width={'100%'}>
                  <Box
                    width={'100%'}
                    padding={2}
                    borderRadius={'10px'}
                    style={{ filter: 'drop-shadow(0px 0px 24px #00000040)' }}
                    bgcolor={Colors.DarkBlue}>
                    <Typography
                      marginBottom={2}
                      color={Colors.White}
                      variant={'h3'}>
                      Potentielle biler
                    </Typography>
                    <Grid container spacing={2}>
                      {m.items.map((item, i) => (
                        <BasicCarGalleryCard
                          key={item.id}
                          origin={BasicCarCardType.AlternativesModal}
                          item={item}
                        />
                      ))}
                    </Grid>
                  </Box>
                </Box>
              )}
            </React.Fragment>
          ))}
          {loading && (
            <Box display="flex" alignItems={'flex-end'} marginBottom={1}>
              <Box height={10} width={10} bgcolor={Colors.Secondary}>
                <Box
                  height={10}
                  width={10}
                  borderRadius={'0px 0px 70% 0px'}
                  bgcolor={Colors.White}
                />
              </Box>
              <Box
                padding={1}
                borderRadius={'12px 12px 12px 0px'}
                bgcolor={Colors.Secondary}>
                <Typography color={Colors.White}>
                  {[1, 2, 3].map(p => (p === loadingTimer ? ' · ' : ' . '))}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          paddingY={1}
          paddingLeft={'18px'}
          paddingRight={1}>
          <Box display={'flex'} alignItems={'center'} flexGrow={1}>
            <TextareaAutosize
              maxRows={3}
              onFocus={() => setInputInFocus(true)}
              onBlur={() => setInputInFocus(false)}
              ref={inputRef}
              style={{
                fontFamily: 'inherit',
                border: 'none',
                resize: 'none',
                width: '100%',
                outline: 'none',
                fontSize: 16,
              }}
              value={message}
              onKeyDown={e => {
                e.stopPropagation();
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  sendMessage();
                }
              }}
              onChange={e => setMessage(e.target.value)}
              placeholder="Jeg leder efter..."
            />
          </Box>
          <Box
            component={IconButton}
            onTouchEnd={() => sendMessage()}
            onClick={() => sendMessage()}
            borderRadius={'50%'}
            bgcolor={Colors.Secondary}
            style={{ cursor: 'pointer' }}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
            height={40}
            width={40}>
            <img
              alt="Send besked"
              src={PaperPlane.src}
              width={24}
              height={24}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
