import { ClassifiedApi, Configuration } from '@/shared/lib-api';
import {
  ClassifiedItem,
  ClassifiedStatus,
  LeadProductStatus,
  SalesType,
  UserProfileType,
} from '@/shared/lib-api/models';
import { useAppSelector } from '@/shared/store/hooks';
import { useApi } from '@/shared/util/api';
import { TrackClassifiedItemEvent } from '@/shared/util/gtm';
import { RemoveUndefinedsFromObject } from '@/shared/util/helpers/next-helpers/node-server-helper';
import {
  getClassifiedVipUrl,
  getClassifiedVipUrlPreSluged,
  getCvrDealerVipUrlPreSluged,
  getDealerVipUrlPreSluged,
} from '@/web/util/helpers/url-helpers';
import { SRPSubTopBar } from '@/web/views/components/SRP/srp-sub-top-bar';
import { SRPTopBar } from '@/web/views/components/SRP/srp-top-bar';
//import BiltemaVipCard from '@/web/views/components/advertisement/biltema/vip-card';
import TjekLaanVipCard from '@/web/views/components/advertisement/financing/vip-card/tjeklaan-card';
import BillaanVipCard from '@/web/views/components/advertisement/financing/vip-card/vip-card';
import SamlinoVipCard from '@/web/views/components/advertisement/samlino/vip-card';
import SosVipCard from '@/web/views/components/advertisement/sos/vip-card/vip-card';
import ClassificationLimitWrapper from '@/web/views/components/containers/classification-limit-wrapper';
import { ConditionalFader } from '@/web/views/components/containers/conditional-fader';
import { ClassifiedShare } from '@/web/views/components/default-components/classified/classified-share';
import { VipBreadcrumbs } from '@/web/views/components/vip/breadcrumbs';
import { VipAutoconceptCard } from '@/web/views/components/vip/card-autoconcept';
import { VipCreateSearchAgentCard } from '@/web/views/components/vip/card-create-searchagent';
import { VipDealerCard } from '@/web/views/components/vip/card-dealer';
import { VipDealerMapCard } from '@/web/views/components/vip/card-dealer-map';
import { VipDealerMapCardXs } from '@/web/views/components/vip/card-dealer-map-xs';
import { VipDescriptionCard } from '@/web/views/components/vip/card-description';
import { VipEquipmentCard } from '@/web/views/components/vip/card-equipment';
import { VipFdmCard } from '@/web/views/components/vip/card-fdm';
import { VipFragusCard } from '@/web/views/components/vip/card-fragus';
import { VipLeasingSpecCard } from '@/web/views/components/vip/card-leasing-spec';
import { VipOwnerActionsCard } from '@/web/views/components/vip/card-owner-actions';
import { VipRelatedCarCard } from '@/web/views/components/vip/card-related-car';
import { VipSecurityCard } from '@/web/views/components/vip/card-security';
import { VipSpecCard } from '@/web/views/components/vip/card-spec';
import { VipToyotaRelaxCard } from '@/web/views/components/vip/card-toyota-relax';
import { CardWriteToDealer } from '@/web/views/components/vip/card-write-to-dealer';
import { VipGallery } from '@/web/views/components/vip/gallery';
import VipHead from '@/web/views/components/vip/head';
import { VipStickyContactBar } from '@/web/views/components/vip/sticky-contact-bar';
import { Recommendations } from '@/web/views/components/vip/vip-recommendations';
import { Box, Button, Container, Grid } from '@mui/material';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TagManager from 'react-gtm-module';
import { useScreenSizeContext, useSiteSettingContext } from '../_app';
import Cookies from 'js-cookie';
import { Chatbot } from '@/web/views/components/vip/chatbot/chatbot';
import ContactConsentModal from '@/web/views/components/modals/contactConsentModal';

export const VipContext = createContext<VipContextprops>({});
export const useVipContext = () => {
  const context = useContext(VipContext);
  return context;
};
export interface VipContextprops {
  item?: ClassifiedItem;
  seezBuyNowUrl?: string;
  toggleActive?: () => void;
  askForContactConsent?: () => void;
}
const VipHeaderCard = dynamic(
  () => import('@/web/views/components/vip/card-header'),
  { ssr: true },
);

export interface VipPageProps {
  item?: ClassifiedItem;
  seezBuyNowUrl?: string;
}

const VipPage: NextPage<VipPageProps> = props => {
  const user = useAppSelector(state => state.auth.cachedUser);
  const [item, setItem] = useState(props.item);
  const { isMobile } = useScreenSizeContext();
  const [financingAvailable, setFinancingAvailable] = useState<boolean>(false);
  const [insuranceAvailable, setInsuranceAvailable] = useState<boolean>(false);
  const siteSettings = useSiteSettingContext();

  const { classifiedApi, chatbotApi } = useApi();
  const rightRef = useRef<HTMLDivElement>(null);
  const leftRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.item) {
      setItem(props.item);
    }
  }, [props.item]);

  useEffect(() => {
    if (item) {
      TagManager.dataLayer({
        dataLayer: {
          dlv_pagetype: 'classified_vip',
        },
      });
      TrackClassifiedItemEvent('DL_CLASSIFED_VIP', item);
      classifiedApi
        .apiClassifiedTrackviewIdPost({ id: item.id })
        .then(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleOnIsFinancingAvailable = useCallback(
    (isAvailable: boolean) => {
      setFinancingAvailable(isAvailable);
    },
    [setFinancingAvailable],
  );

  const [adminActive, setAdminActive] = useState(false);
  const [showContactConsent, setShowContactConsent] = useState(false);

  useEffect(() => {
    chatbotApi.apiChatbotIsactiveGet().then(res => {
      setAdminActive(res.active);
    });
  }, [chatbotApi]);

  var chatbot = useMemo(() => {
    return (
      ((Cookies.get('chatbot') == 'test' &&
        (siteSettings.name == 'Bilhandel' ||
          siteSettings.name == 'Localhost')) ||
        adminActive) &&
      !(
        !item ||
        item.systemData.isSold ||
        item.systemData.status === ClassifiedStatus.Inactive
      )
    );
  }, [adminActive, siteSettings, item]);

  if (!item) {
    return null;
  }

  const isOwner = user && user.id === item.owner.id;
  const isLimitedVipView =
    (item.systemData.status === ClassifiedStatus.Sold ||
      item.systemData.status === ClassifiedStatus.Inactive) &&
    !isOwner;
  const toyotaRelax = item.owner.offersToyotaRelax && item.hasGuarantee;

  const isSold =
    !item ||
    item.systemData.isSold ||
    item.systemData.status === ClassifiedStatus.Inactive;

  const autoConcept = false;
  // item.owner.offersAutoconcept &&
  // (item.owner.useAutoconceptOnAllVehicles || item.hasGuarantee);

  const fragus =
    item.owner.offersFragus &&
    (item.owner.useFragusOnAllVehicles || item.hasGuarantee);

  const isElectric = ['El', 'Hybrid', 'Benzin/El', 'Diesel/El'].includes(
    item?.fueltype,
  );

  const isPrivate = item.owner.profileType === UserProfileType.Private;
  const lessThanEightYearOld =
    Math.abs(Number.parseInt(item.year, 10) - new Date().getFullYear()) < 8 &&
    item.owner.profileType == UserProfileType.Private;

  const displayLendo =
    !item.owner.financingOptedOut &&
    item.systemData.salesType === SalesType.Sell &&
    item.owner.profileType === UserProfileType.Company &&
    item.systemData.price >= 50000;

  const displayTjekLaan =
    !item.owner.financingOptedOut &&
    item.systemData.salesType === SalesType.Sell &&
    (item.owner.profileType === UserProfileType.Private ||
      item.systemData.price <= 50000);

  return (
    <>
      <VipContext.Provider
        value={{
          item: item,
          seezBuyNowUrl: props.seezBuyNowUrl,
          toggleActive: () => {
            var newItem: ClassifiedItem = {
              ...item,
              systemData: {
                ...item.systemData,
                isActive: !item.systemData.isActive,
              },
            };
            setItem(newItem);
          },
          askForContactConsent: () => {
            setShowContactConsent(true);
          }
        }}>
        {(item.owner.leadProductStatus === undefined ||
          item.owner.leadProductStatus === LeadProductStatus.Active) &&
          item.owner.isLead &&
          item?.images?.length > 2 && (
            <Box
              component="p"
              style={{ position: 'fixed', color: 'transparent', zIndex: -1 }}
              id="lead-bil">
              lead-bil
            </Box>
          )}
        <VipHead />
        <VipStickyContactBar />
        <SRPTopBar />
        <SRPSubTopBar />
        <Container>
          {item && <ContactConsentModal open={showContactConsent} onClose={() => setShowContactConsent(false)} classifiedId={item.id} /> }
          <ConditionalFader
            condition={isLimitedVipView}
            maxHeight={'800px'}
            mobileMaxHeight={'1000px'}>
            <Box
              display="flex"
              width={'100%'}
              justifyContent={'center'}
              justifyItems={'center'}
              alignItems={'center'}
              sx={{ marginTop: { xs: '15px', md: '37px' } }}>
              <Box
                sx={{
                  marginRight: { xs: 'auto' },
                }}>
                <VipBreadcrumbs />
              </Box>
              {!isLimitedVipView && (
                <>
                  <Box
                    sx={{ ml: '30px', display: { xs: 'none', md: 'block' } }}>
                    <ClassifiedShare />
                  </Box>
                </>
              )}
            </Box>
            <Grid
              container
              spacing={0}
              sx={{ marginTop: { xs: '15px', md: '18px' } }}>
              <VipGallery />
              <VipHeaderCard />
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={8}>
                    <Box ref={leftRef}>
                      {isOwner && <VipOwnerActionsCard />}
                      <VipRelatedCarCard />
                      <VipDescriptionCard />
                      {toyotaRelax ? (
                        <VipToyotaRelaxCard />
                      ) : autoConcept ? (
                        <VipAutoconceptCard />
                      ) : fragus ? (
                        <VipFragusCard />
                      ) : (
                        <VipSecurityCard />
                      )}
                    </Box>
                    {leftRef.current &&
                      rightRef.current &&
                      rightRef.current.clientHeight >
                        leftRef.current.clientHeight && <VipSpecCard />}
                  </Grid>
                  <Grid paddingLeft={isMobile ? 0 : 3} item xs={12} md={4}>
                    <Box ref={rightRef}>
                      <Grid container>
                        {!isMobile && (
                          <Grid item xs={12}>
                            <VipDealerCard />
                          </Grid>
                        )}
                      </Grid>
                      {!isSold && !isMobile && (
                        <>
                          {lessThanEightYearOld ? (
                            <VipFdmCard />
                          ) : (
                            <VipCreateSearchAgentCard />
                          )}
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {!isLimitedVipView && (
                <>
                  {leftRef.current &&
                    rightRef.current &&
                    rightRef.current.clientHeight <
                      leftRef.current.clientHeight && (
                      <Grid item xs={12}>
                        <VipSpecCard />
                      </Grid>
                    )}

                  <Grid item xs={12}>
                    <VipEquipmentCard />
                  </Grid>
                  {isMobile &&
                    item.owner.profileType === UserProfileType.Company &&
                    item.owner.writeEnabled && (
                      <Grid item xs={12} id="write-to-dealer-container">
                        <CardWriteToDealer />
                      </Grid>
                    )}
                  {item.leasingSpecifications.length > 0 && (
                    <Grid item xs={12}>
                      <VipLeasingSpecCard />
                    </Grid>
                  )}
                  <Grid
                    id="billan-vip-card"
                    container
                    columnSpacing={3}
                    rowGap={'15px'}
                    item
                    xs={12}
                    sx={{ marginTop: { xs: '15px', md: '18px' } }}>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      display={financingAvailable ? 'block' : 'none'}>
                      {displayLendo && (
                        <BillaanVipCard
                          classifiedId={item.id}
                          amount={item.systemData.price}
                          onIsAvailable={handleOnIsFinancingAvailable}
                        />
                      )}
                    </Grid>
                    {displayTjekLaan && (
                      <Grid item xs={12} lg={4}>
                        {/* <MoreBankerVipCard /> */}
                        <TjekLaanVipCard />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      display={insuranceAvailable ? 'block' : 'none'}>
                      <SamlinoVipCard
                        classifiedId={item.id}
                        make={item.make}
                        model={item.model}
                        variant={item.variant}
                        year={item.year}
                        category={item.systemData.category}
                        onIsAvailable={isAvailable => {
                          setInsuranceAvailable(isAvailable);
                        }}
                      />
                    </Grid>
                    {/* {item.year &&
                      item.owner.profileType === UserProfileType.Company &&
                      new Date().getFullYear() - parseInt(item.year) < 6 && (
                        <Grid item xs={12} lg={4}>
                          <BiltemaVipCard />
                        </Grid>
                      )} */}
                    {item.year &&
                      item.owner.profileType === UserProfileType.Company &&
                      new Date().getFullYear() - parseInt(item.year) >= 6 && (
                        <Grid item xs={12} lg={4}>
                          <SosVipCard />
                        </Grid>
                      )}
                  </Grid>
                  {isMobile && (
                    <Grid item xs={12}>
                      <VipDealerMapCardXs />
                    </Grid>
                  )}
                  {!isMobile && (
                    <Grid item xs={12}>
                      <VipDealerMapCard />
                    </Grid>
                  )}
                </>
              )}
              {!isSold && isMobile && (
                <Grid item xs={12}>
                  {lessThanEightYearOld ? (
                    <VipFdmCard />
                  ) : (
                    <VipCreateSearchAgentCard />
                  )}
                </Grid>
              )}
            </Grid>
          </ConditionalFader>
          <ClassificationLimitWrapper classification={50}>
            <Recommendations classifiedId={item.id} />
          </ClassificationLimitWrapper>
          {chatbot && !isPrivate && <Chatbot />}
        </Container>
      </VipContext.Provider>
    </>
  );
};

export default VipPage;

export async function getServerSideProps({ query }) {
  const queryId: string[] = query.id as string[];
  // old urls
  if (!queryId) {
    return { notFound: true };
  } else if (queryId?.length === 2) {
    // old cvr vip url
    const cvrRegEx: RegExp = /cvr-(\d+)$/;
    const cvrMatch = queryId[1].match(cvrRegEx);

    if (cvrMatch?.length === 2) {
      const cvr_name_slug = query.headline as string;
      const cvr_city_clug = queryId[0] as string;
      const cvr = +cvrMatch[1];

      const url = getCvrDealerVipUrlPreSluged(
        cvr_name_slug,
        cvr_city_clug,
        cvr,
      );
      return {
        redirect: {
          permanent: true,
          destination: url,
        },
        props: {},
      };
    }
    // end old cvr vip url

    // old dealer vip url
    const idRegEx: RegExp = /id-(\d+)$/;
    const idMatch = queryId[1].match(idRegEx);

    if (idMatch?.length === 2) {
      const dealer_name_slug = query.headline as string;
      const dealer_city_clug = queryId[0] as string;
      const dealer_id = +idMatch[1];

      const url = getDealerVipUrlPreSluged(
        dealer_name_slug,
        dealer_city_clug,
        0,
        dealer_id,
      );
      return {
        redirect: {
          permanent: true,
          destination: url,
        },
        props: {},
      };
    }

    return { notFound: true };
  } else if (queryId?.length > 2) {
    return { notFound: true };
  }
  // end old dealer vip url
  // end old urls

  const idRegEx: RegExp = /id-(\d+)$/;
  const idMatch = queryId[0].match(idRegEx);

  if (idMatch?.length !== 2) {
    return { notFound: true };
  }

  const id: number = +idMatch[1];
  const headline_slug: string = query.headline as string;
  const api = new ClassifiedApi(
    new Configuration({
      basePath: process.env.INTERNAL_SITE_API,
    }),
  );

  let item: ClassifiedItem | undefined;
  try {
    item = await api.apiClassifiedIdGet({ id });
  } catch (error) {
    console.log(error);
  }
  if (!item) {
    return { notFound: true };
  }

  if (item.systemData.status === ClassifiedStatus.Deleted) {
    // redirect to home
    return {
      redirect: {
        permanent: true,
        destination: '/?deleted',
      },
      props: {},
    };
  }

  let seezBuyNowUrl: string = '';
  await api
    .apiClassifiedSeezbuynowPost({ classifiedId: id })
    .then(url => {
      seezBuyNowUrl = url;
    })
    .catch(() => {});

  // make sure the slug is correct
  const correctUrl = getClassifiedVipUrl(item.id, item.headLine);
  const currentUrl = getClassifiedVipUrlPreSluged(id, headline_slug);
  if (currentUrl !== correctUrl) {
    return {
      redirect: {
        permanent: true,
        destination: correctUrl,
      },
      props: {},
    };
  }
  return {
    props: {
      item: RemoveUndefinedsFromObject(item),
      seezBuyNowUrl,
    },
  };
}
