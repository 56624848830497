import React, { useCallback, useState } from 'react';
import { useApi } from '@/shared/util/api';
import { object, ObjectSchema, string, boolean, number } from 'yup';
import { WriteToDealerValues } from '@/shared/interfaces/writeToDealerValues';
import { useTranslation } from 'react-i18next';
import { useRequestHelper } from '../request-helper';
import { SchemaOf } from 'yup';
import { LoadingState } from '@/shared/util/general-helpers';
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks';
import { BuildInTrackEvents, TrackEvent } from '@/shared/util/gtm';
import { useAuth } from '../auth-hook';
import TagManager from 'react-gtm-module';
import { setUser } from '@/shared/store/reducers/AuthSlice';

export function useWriteToDealer(
  classifiedId: number,
  ownerId: number,
  callOptionInitialChecked: boolean,
  testDriveOptionInitialChecked: boolean,
  tradeOptionInitialChecked: boolean,
  leasingOfferInitialChecked: boolean,
) {
  const { isLoggedIn } = useAuth();
  const { t } = useTranslation();
  const { dealerApi, authApi } = useApi();
  const localStorageKey = 'write-to-dealer';

  const dispatch = useAppDispatch();

  const zipCodeRequired = ownerId === 168267; // toyota danmark

  const personalInfo = useAppSelector(
    state => state.auth.cachedUser?.personalInfo,
  );

  const drivingData = useAppSelector(
    state => state.auth.cachedUser?.drivingData,
  );

  let initialValues: WriteToDealerValues = {
    name: '',
    emailAddress: '',
    phoneNumber: '',
    message: callOptionInitialChecked
      ? 'Hej, jeg er interesseret i bilen og vil gerne ringes op'
      : '',
    acceptService: false,
    signUpToNewsletter: false,
    call: callOptionInitialChecked,
    testDrive: testDriveOptionInitialChecked,
    trade: tradeOptionInitialChecked,
    leasingOffer: leasingOfferInitialChecked,
    testDriveTime: '',
    licensePlate: '',
    mileage: '',
    zipCode: '',
    greenbow: false,
  };

  // make sure we are on the client
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(localStorageKey);
    const savedInput = JSON.parse(saved);

    if (savedInput) {
      // initial values last submit
      initialValues.name = savedInput.name;
      initialValues.emailAddress = savedInput.emailAddress;
      initialValues.phoneNumber = savedInput.phoneNumber;
      initialValues.zipCode = savedInput.zipCode;
      initialValues.message = savedInput.message;
      initialValues.licensePlate =
        savedInput?.licensePlate ?? drivingData?.regNr ?? '';
      initialValues.mileage =
        savedInput?.mileage ?? drivingData?.mileage?.toString() ?? '';
    } else {
      // initial values from profile
      initialValues.name = personalInfo?.screenName ?? '';
      initialValues.emailAddress = personalInfo?.email ?? '';
      initialValues.phoneNumber = personalInfo?.phoneNumber ?? '';
      initialValues.licensePlate = drivingData?.regNr ?? '';
      initialValues.mileage = drivingData?.mileage?.toString() ?? '';
    }
  }

  const { handleRequest, requestState, errorMessage } = useRequestHelper();

  const onSubmit = useCallback(
    async (values: WriteToDealerValues, onSuccess: () => void) => {
      const promise = dealerApi.apiDealerWriteToDealerPost({
        writeToDealerRequest: {
          classifiedId: classifiedId,
          dealerUserId: ownerId,
          name: values.name,
          emailAddress: values.emailAddress,
          phoneNumber: values.phoneNumber,
          message: values.message,
          acceptService: values.acceptService,
          signUpToNewsletter: values.signUpToNewsletter,
          call: values.call,
          testDrive: values.testDrive,
          trade: values.trade,
          leasingOffer: values.leasingOffer,
          testDriveTime: values.testDriveTime,
          licensePlate: values.licensePlate,
          mileage: values.mileage,
          zipCode: values.zipCode,
        },
      });

      if (
        (values.mileage &&
          values.mileage !== '' &&
          values.mileage !== drivingData?.mileage?.toString()) ||
        (values.licensePlate &&
          values.licensePlate !== '' &&
          values.licensePlate !== drivingData?.regNr)
      ) {
        try {
          var mileage = Number.parseInt(values.mileage, 10);
          const updateuserPromise =
            authApi.apiAuthUpdatedriverinformationdataPost({
              driverInformationData: {
                licensePlate: values.licensePlate,
                mileage: mileage,
                yearsWithoutIncidents: drivingData?.yearsWithoutIncidents,
                drivingPerYear: drivingData?.mileagePerYear,
              },
            });
          const [data, errorAndState] = await handleRequest(updateuserPromise);
          if (errorAndState.state === LoadingState.Success) {
            dispatch(setUser(data));
          }
        } catch {}
      }

      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          name: values.name,
          emailAddress: values.emailAddress,
          phoneNumber: values.phoneNumber,
          message: values.message,
          mileage: values.mileage,
          licensePlate: values.licensePlate,
          zipCode: values.zipCode,
        }),
      );

      const [result, errorAndState] = await handleRequest(promise);
      if (errorAndState.state === LoadingState.Success) {
        TrackEvent(BuildInTrackEvents.OnDealerMessageSent, {
          dlv_dealer_id: ownerId,
          dlv_dealer_write_option_call: values.call,
          dlv_dealer_write_option_testdrive: values.testDrive,
          dlv_dealer_write_option_trade: values.trade,
          dlv_logged_in_state: isLoggedIn,
          dlv_user_email: values.emailAddress,
          dlv_user_screenname: values.name,
          dlv_user_phone: values.phoneNumber,
          dlv_user_zip: undefined,
          dlv_user_city: undefined,
        });

        // reset data layer varibles
        TagManager.dataLayer({
          dataLayer: {
            dlv_logged_in_state: isLoggedIn,
            dlv_user_email: personalInfo?.email,
            dlv_user_screenname: personalInfo?.screenName,
            dlv_user_zip: personalInfo?.zipCode,
            dlv_user_city: personalInfo?.city,
            dlv_user_phone: personalInfo?.phoneNumber,
          },
        });

        onSuccess();
      }
    },
    [
      dealerApi,
      handleRequest,
      classifiedId,
      ownerId,
      personalInfo,
      isLoggedIn,
      authApi,
      drivingData,
      dispatch,
    ],
  );

  const [validationSchema] = useState<SchemaOf<WriteToDealerValues>>(
    object().shape({
      classifiedId: number(),
      name: string().required(
        t('Validation.FieldRequired', { label: t('Auth.Name') }),
      ),
      emailAddress: string()
        .required(t('Validation.FieldRequired', { label: t('Auth.Email') }))
        .email(t('Validation.FieldEmail', { label: t('Auth.Email') })),
      phoneNumber: string().when('call', {
        is: true,
        then: string().required(' '),
      }),
      message: string().when('testDrive', {
        is: false,
        then: string().required(
          t('Validation.FieldRequired', {
            label: t('Vip.WriteToDealer.MessageTitle'),
          }),
        ),
      }),
      signUpToNewsletter: boolean(),
      acceptService: boolean().oneOf(
        [true],
        t('Validation.TermAcceptRequired'),
      ),
      call: boolean(),
      trade: boolean(),
      testDrive: boolean(),
      leasingOffer: boolean(),
      //testDriveTime: string().required('Vælg tidspunkt'),
      testDriveTime: string().when('testDrive', {
        is: true,
        then: string().required('Vælg dato og tidspunkt'),
      }),
      licensePlate: string().when('trade', {
        is: true,
        then: string().required(' '),
      }),
      mileage: string().when('trade', {
        is: true,
        then: string().required(' '),
      }),
      zipCode: string().when([], () => {
        return zipCodeRequired ? string().required(' ') : string();
      }),
      greenbow: boolean(),
    }),
  );

  return {
    validationSchema,
    initialValues,
    onSubmit,
    errorMessage,
    requestState,
    zipCodeRequired,
  };
}
