import { SearchPageOptions } from '@/shared/lib-api';
import React from 'react';

export const GetNoIndexMeta = (
  options: SearchPageOptions,
  path: string,
  totalResults: number,
) => {
  if (
    totalResults > 0 &&
    options.makeModelVariantOptions.length !== 0 &&
    (options.makeModelVariantOptions[0].brand.value !== '' ||
      options.makeModelVariantOptions[0].model.value !== '') &&
    options.carTypes.length === 0 &&
    options.fuelTypes.length === 0 &&
    options?.area === undefined
  ) {
  } else if (totalResults > 3) {
  } else {
    return <meta name="robots" content="noindex" />;
  }
  if (options.category === undefined || options.category.value === '') {
    return <meta name="robots" content="noindex" />;
  }
  if (options.pageNumber !== undefined && options.pageNumber >= 4) {
    return <meta name="robots" content="noindex" />;
  }
  return <></>;
};
